<i18n>
ru:
  about: Предложения
  becomePartner: Станьте нашим партнером
  commercialOffer: Коммерческое предложение
  email: E-mail
  name: ФИО
  phoneNumber: Телефон
  send: Отправить анкету
  successMessage: Анкета успешно отправлена
ua:
  about: Пропозиції
  becomePartner: Станьте нашим партнером
  commercialOffer: Комерційна пропозиція
  email: E-mail
  name: ПІБ
  phoneNumber: Телефон
  send: Надіслати анкету
  successMessage: Анкета успішно відправлена
us:
  about: Proposals
  becomePartner: Become our partner
  commercialOffer: Commercial offer
  email: E-mail
  name: Full name
  phoneNumber: Phone number
  send: Submit the form
  successMessage: Form submitted successfully
</i18n>

<template>
  <div
    id="v-partners-form"
    class="v-partners-form"
    v-show="!!partnersFields.partnersId"
  >
    <div class="v-row v-w-100">
      <div
        class="v-col-12 v-col-md-10 v-offset-md-1 v-col-xl-8 v-offset-xl-2 v-mb-md v-py-sm v-partners-form--title"
        v-html="translate('partnershipForm.becomePartner')"
      />
      <div
        class="v-col-12 v-col-md-10 v-col-xl-8 v-offset-md-1 v-offset-xl-2 v-partners-form--inputs-wrapper"
      >
        <div class="v-partners-form--input">
          <v-input
            class-name="v-arora-input"
            v-form-validator="{
              Form: validatorForm,
              Value: partnersFields.name,
              Required: appConfig.VueSettingsPreRun.PartnershipRequireName,
              Validations: ['length'],
              MaxLength: 255
            }"
            :label="translate('partnershipForm.name')"
            :required="appConfig.VueSettingsPreRun.PartnershipRequireName"
            v-model:text="partnersFields.name"
          />
        </div>
        <div class="v-partners-form--input">
          <common-phone-input
            :label="translate('partnershipForm.phoneNumber')"
            :required="appConfig.VueSettingsPreRun.PartnershipRequirePhone"
            :validator-form="validatorForm"
            v-model:phone="partnersFields.phone"
          />
        </div>
        <div class="v-partners-form--input">
          <v-email-input
            class-name="v-arora-input"
            v-form-validator="{
              Form: validatorForm,
              Value: partnersFields.email,
              Required: appConfig.VueSettingsPreRun.PartnershipRequireEmail,
              Validations: ['email', 'length'],
              MaxLength: 64
            }"
            :label="translate('partnershipForm.email')"
            :required="appConfig.VueSettingsPreRun.PartnershipRequireEmail"
            v-model:text="partnersFields.email"
          />
        </div>
      </div>

      <div class="v-col-12 v-col-md-10 v-col-xl-8 v-offset-md-1 v-offset-xl-2">
        <arora-textarea
          v-form-validator="{
            Form: validatorForm,
            Value: partnersFields.about,
            Required: appConfig.VueSettingsPreRun.PartnershipRequireAbout,
            Validations: ['length'],
            MaxLength: 255
          }"
          :label="translate('partnershipForm.about')"
          :required="appConfig.VueSettingsPreRun.PartnershipRequireAbout"
          :textarea-rows="4"
          v-model:text="partnersFields.about"
        />
      </div>

      <div class="v-col-12 v-col-md-10 v-col-xl-8 v-offset-md-1 v-offset-xl-2 v-mb-sm">
        <arora-file-input
          v-form-validator="{
            Form: validatorForm,
            Value: partnersFields.attachment ? '#' : null,
            Required: appConfig.VueSettingsPreRun.PartnershipRequireFile,
            Validations: []
          }"
          :allowed-types="[MIME.pdf, MIME.doc, MIME.docx, MIME.ppt, MIME.pptx]"
          :label="translate('partnershipForm.commercialOffer')"
          :max-bytes="maxBytes"
          :required="appConfig.VueSettingsPreRun.PartnershipRequireFile"
          v-model:file="partnersFields.attachment"
        />
      </div>

      <div class="v-col-12 v-col-md-10 v-col-xl-8 v-offset-md-1 v-offset-xl-2 v-mb-sm">
        <lazy-common-captcha />
      </div>
      <div class="v-col-12 v-col-md-10 v-col-xl-8 v-offset-md-1 v-offset-xl-2 v-mb-sm">
        <transition
          appear
          mode="out-in"
          name="fade"
        >
          <div
            v-if="error"
            class="v-partners-popup-button-block v-error-color"
            v-html="error"
          />
        </transition>
      </div>

      <div
        v-if="appConfig.VueSettingsPreRun.EnablePersonalDataWarning"
        class="v-col-12 v-col-md-10 v-col-xl-8 v-offset-md-1 v-offset-xl-2 v-mb-sm"
      >
        <span
          class="v-mr-xxs"
          v-html="translate('feedbackPopup.personalDataMessage')"
        />
        <arora-nuxt-link
          open-in-new-tab
          :href="appConfig.VueSettingsPreRun.Links.PersonalDataLink"
          :label="translate('feedbackPopup.personalDataMessageLink')"
        />
      </div>
      <div class="v-col-12 v-col-md-10 v-col-xl-8 v-offset-md-1 v-offset-xl-2">
        <div class="v-d-flex v-justify-content-end">
          <arora-button
            :disabled="lockButton"
            :label="translate('partnershipForm.send')"
            @click="async () => await send()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PartnersOfferPayload } from '~types/pageStore'

import {
  MIME,
  useValidationStore,
  VEmailInput,
  vFormValidator,
  VInput
} from '@arora/common'

import { getActivePinia } from 'pinia'
import { AuthOperationsErrorCode, Guid } from '~api/consts'

const restaurantStore = useRestaurantStore()
const accountStore = useAccountStore()
const pageStore = usePageStore()
const popupStore = usePopupStore()

const validationStore = useValidationStore(getActivePinia())

const validatorForm = 'partners'

const { clean, translate } = useI18nSanitized()
const { eventEmit } = useEmitter()
const appConfig = useAppConfig()

const partnersFields = ref<PartnersOfferPayload>({
  about: undefined as string | undefined,
  attachment: null as File | null,
  email: undefined as string | undefined,
  name: undefined as string | undefined,
  partnersId: pageStore.SelectedPartnershipId ?? Guid.Empty,
  passedV3: true,
  phone: undefined as string | undefined,
  recaptcha: null as string | null,
  smartcaptcha: null as string | null
})

onMounted(() => {
  if (accountStore.isLoggedIn && accountStore.Profile.data) {
    const profileData = accountStore.Profile.data

    partnersFields.value.name = profileData.Name
    partnersFields.value.email = profileData.Email
    partnersFields.value.phone = profileData.Phone
  }
})

const error = ref<string | null>(null)
const lockButton = ref<boolean>(false)

const maxBytes = 200 * 1024

async function send(): Promise<void> {
  lockButton.value = true
  error.value = null

  partnersFields.value.name = clean(partnersFields.value.name)
  partnersFields.value.phone = clean(partnersFields.value.phone)
  partnersFields.value.email = clean(partnersFields.value.email)
  partnersFields.value.about = clean(partnersFields.value.about)

  partnersFields.value = await restaurantStore.validateCaptcha(partnersFields.value)

  if (!validationStore.formPassedCheck(validatorForm)) {
    lockButton.value = false

    return
  }
  const code = await pageStore.sendPartners(partnersFields.value)
  lockButton.value = false

  switch (code) {
    case 0: {
      await popupStore.showSuccess(translate('partnershipForm.successMessage'))

      return
    }
    case AuthOperationsErrorCode.CaptchaWasNotVerified:
    case AuthOperationsErrorCode.CaptchaV3Failed: {
      error.value = translate('accountManagement.captchaValidationError')
      eventEmit('v-reset-captcha')
      break
    }
    default: {
      error.value = translate('feedbackPopup.ajaxErrorUnknown')
      eventEmit('v-reset-captcha')
      return
    }
  }
}
watch(
  () => pageStore.SelectedPartnershipId,
  (newValue, oldValue) => {
    if (newValue && newValue !== oldValue) {
      partnersFields.value.partnersId = newValue
    }
  }
)
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/mixins';

.v-partners-form {
  display: flex;
  background: variables.$BodyElementsBackground;
  border-radius: variables.$BorderRadius;
  box-shadow: variables.$CardShadow;
  padding: 1.5rem;

  &--title {
    font-weight: 600;
    font-size: variables.$TextSizeH2;
  }

  &--inputs-wrapper {
    display: flex;
    gap: variables.$GridGutter;

    @include mixins.md {
      flex-direction: column;
      gap: 0;
    }
  }

  &--input {
    flex-grow: 1;
    flex-basis: 0;
  }

  @include mixins.sm {
    padding: 1rem;
  }
}
</style>
